import React from "react";
import MarkdownComponent from "../../MarkdownComponent";
import PythonPlot from "./PythonPlot";

function IntroDataAnalysis() {
  return (
    <div>
      <h1>Data Analysis</h1>
      <MarkdownComponent path="/DataAnalysis/DataAnalysisIntro.md" />
      <PythonPlot />
    </div>
  );
}
export default IntroDataAnalysis;
