import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import axios from "axios";

const PythonPlot = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const loadPyodide = async () => {
      const pyodide = await window.loadPyodide({
        indexURL: "https://cdn.jsdelivr.net/pyodide/v0.23.4/full/",
      });

      // Asenna plotly micropipillä
      try {
        await pyodide.loadPackage("micropip");
        await pyodide.runPythonAsync(`
            import micropip
            await micropip.install('plotly')
            await micropip.install('pandas')
        `);
        console.log("Plotly installed!");
      } catch (error) {
        console.error("Error installing plotly:", error);
      }
      const code = `
                import plotly.express as px
                import json
                import pandas as pd
                df = pd.read_csv('eElectric_prices.csv')
                #df = px.data.iris()
                fig = px.scatter(df, x="Date", y="Price", color="Area", title="Electricity prices")
                # Muunna JSONiksi varmistaen, että numpy.ndarray on serialisoitu
                fig_json = json.dumps(fig.to_plotly_json(), default=lambda o: o.tolist() if hasattr(o, 'tolist') else o)
                fig_json
                #json.dumps(fig.to_dict())
            `;
      const result = await pyodide.runPythonAsync(code);
      setChartData(JSON.parse(result));
    };

    loadPyodide();

    // axios
    //   .get("http://localhost:5000/chart-data")
    //   .then((response) => setChartData(response.data))
    //   .catch((error) => console.error(error));
  }, []);

  return (
    <div>
      {chartData ? (
        <Plot data={chartData.data} layout={chartData.layout} />
      ) : (
        <p>Loading pyodide...</p>
      )}

      {/* {chartData ? (
        <Plot data={chartData.data} layout={chartData.layout} />
      ) : (
        <p>Loading backend...</p>
      )} */}
    </div>
  );
};

export default PythonPlot;
